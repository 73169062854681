import React, { useEffect, useState } from "react";
import "./NavBar.scss";

import scrollTo from "gatsby-plugin-smoothscroll";
import Switch from "../switch/Switch";

const NavBar = ({ isChecked, setIsChecked, activeClass, setActiveClass }) => {
  const [isMobileNavBarActive, setIsMobileNavBarActive] = useState(false);
  const navOptions = [
    {
      ref: "#mh-home",
      text: "Home",
    },
    {
      ref: "#mh-about",
      text: "About",
    },
    {
      ref: "#mh-experience",
      text: "Experiences",
    },
    {
      ref: "#mh-contact",
      text: "Contact",
    },
    {
      href: "https://blog.webtric.be",
      text: "Blog",
    },
  ];

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setIsChecked(true);
    }
  }, [setIsChecked]);

  const setActiveClassAndScrollTo = newActiveClass => {
    if (!newActiveClass) {
      return;
    }
    setActiveClass(newActiveClass);
    scrollTo(newActiveClass);
    setIsMobileNavBarActive(false);
  };
  return (
    <nav
      className="navbar navbar-expand-lg mh-nav nav-btn"
      data-sal="fade"
      data-sal-duration="0.8s"
    >
      <a
        className="navbar-brand"
        href="/"
        onClick={() => setActiveClassAndScrollTo("#mh-home")}
      >
        <img
          src={`assets/${isChecked ? "webtric-dark" : "webtric"}.svg`}
          alt="Webtric"
          className="img-fluid"
        />
      </a>

      <Switch
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        isMobileNavBarActive={isMobileNavBarActive}
        extraClass={"mobile"}
      />
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setIsMobileNavBarActive(!isMobileNavBarActive)}
      >
        <span className="navbar-toggler-icon icon" />
      </button>

      <div
        className={`collapse navbar-collapse ${
          isMobileNavBarActive ? "active" : ""
        }`}
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav mr-0 ml-auto">
          {navOptions.map(option => {
            return (
              <li
                key={option.ref}
                className={`nav-item ${
                  option.ref === activeClass ? "active" : ""
                }`}
              >
                <a
                  href={option.href ? option.href : "/"}
                  className="nav-link"
                  onClick={() =>
                    option.ref ? setActiveClassAndScrollTo(option.ref) : null
                  }
                >
                  {option.text}
                </a>
              </li>
            );
          })}
          <li
            key={"dark-mode-toggle"}
            className={"nav-item"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Switch
              extraClass={"no-mobile"}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
