import React from "react";

const Education = () => {
  return (
    <section
      className="mh-experince image-bg featured-img-one"
      id="mh-experience"
    >
      <div className="img-color-overlay">
        <div className="container">
          <div className="row section-separator">
            <div className="col-sm-12 col-md-6">
              <div className="mh-education">
                <h3
                  data-sal="fade"
                  data-sal-duration="0.8s"
                  data-sal-delay="200"
                >
                  Education
                </h3>
                <div className="mh-education-deatils">
                  <div
                    className="mh-education-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="300"
                  >
                    <h4>
                      Google Associate Cloud Engineer{" "}
                      <a href="https://www.credential.net/750035d9-0288-4233-8d6b-bf9e8dce5dcf">
                        Certificate
                      </a>
                    </h4>
                    <div className="mh-eduyear">2020</div>
                    <p>
                      This online exam created by Google contains a course of
                      the wide range of services that Google offers. Google
                      Cloud Platform is in my opinion the best cloud provider
                      currently available.
                    </p>
                  </div>
                  <div
                    className="mh-education-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="600"
                  >
                    <h4>
                      Application development @{" "}
                      <a
                        href="https://www.pxl.be/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        PXL University
                      </a>
                    </h4>
                    <div className="mh-eduyear">2015-2018</div>
                    <p>
                      During the bachelor degree I had an amazing introduction
                      to all kind of programming languages using a practical
                      approach based on projects from collaborations with
                      companies like{" "}
                      <a
                        href={"https://mobilevikings.be/en/"}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Mobile Vikings
                      </a>
                      .
                    </p>
                  </div>
                  <div
                    className="mh-education-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="500"
                  >
                    <h4>
                      Drone programming week @{" "}
                      <a
                        href="https://www.pxl.be/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        PXL University
                      </a>
                    </h4>
                    <div className="mh-eduyear">2017</div>
                    <p>
                      This contained a week of working in an international team
                      on programming drones. This course was my first
                      introduction to Python and broadened my view on software
                      programming on specific hardware like drones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="mh-work">
                <h3>Work experience</h3>
                <div className="mh-experience-deatils">
                  <div
                    className="mh-work-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="400"
                  >
                    <h4>
                      Software Engineer{" "}
                      <a
                        href="https://www.datacamp.com"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Datacamp
                      </a>
                    </h4>
                    <div className="mh-eduyear">2021-now</div>
                    <ul className="work-responsibility">
                      <li>
                        <i className="fa fa-circle" />
                        Product development for the Recruit product of DataCamp.
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Full stack development
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Data Analysis and Data Visualization
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mh-work-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="400"
                  >
                    <h4>
                      Full stack developer{" "}
                      <a
                        href="https://www.psylaris.com"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Psylaris
                      </a>
                    </h4>
                    <div className="mh-eduyear">2019-now</div>
                    <ul className="work-responsibility">
                      <li>
                        <i className="fa fa-circle" />
                        Developed a dashboard Psylaris' international users
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Created a{" "}
                        <a
                          href={"https://www.mollie.com/"}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Mollie payment
                        </a>{" "}
                        integration
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Implemented a{" "}
                        <a
                          href={"https://www.twilio.com/"}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Twilio Video
                        </a>{" "}
                        integration
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Converted an existing Unity application into a React
                        variant
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mh-work-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="400"
                  >
                    <h4>
                      Full stack developer{" "}
                      <a
                        href="https://www.craftworkz.be"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Craftworkz
                      </a>
                    </h4>
                    <div className="mh-eduyear">2018-2021</div>
                    <ul className="work-responsibility">
                      <li>
                        <i className="fa fa-circle" />
                        Application development and prototyping innovative ideas
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Chatbot development
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Backend development and CI/CD
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mh-work-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="700"
                  >
                    <h4>
                      Platform developer{" "}
                      <a
                        href="https://www.oswald.ai/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Oswald Platform
                      </a>
                    </h4>
                    <div className="mh-eduyear">2019-2020</div>
                    <ul className="work-responsibility">
                      <li>
                        <i className="fa fa-circle" />
                        Feature development in a production chatbot platform
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Frontend (Ember.js) and backend (node.js) development
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Google Cloud platform
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        End to end testing with Cypress
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mh-work-item dark-bg"
                    data-sal="fade"
                    data-sal-duration="0.8s"
                    data-sal-delay="600"
                  >
                    <h4>
                      Full stack developer for{" "}
                      <a
                        href="https://overheid.vlaanderen.be/beleid-en-regelgeving/werking-en-besluitvorming-vlaamse-regering/kaleidos"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        The flemish government
                      </a>
                    </h4>
                    <div className="mh-eduyear">2018-2019</div>
                    <ul className="work-responsibility">
                      <li>
                        <i className="fa fa-circle" />
                        Application development in Ember.js (Frontend)
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        Backend development in Node.js
                      </li>
                      <li>
                        <i className="fa fa-circle" />
                        CI/CD using Jenkins
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
