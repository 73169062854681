import React from "react";
import "./Home.scss";
import config from "../../../data/SiteConfig.js";

const { userLinks } = config || [];
const Home = () => {
  return (
    <section className="mh-home image-bg home-2-img" id="mh-home">
      <div className="img-foverlay img-color-overlay">
        <div className="container">
          <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
            <div className="col-sm-6">
              <div className="mh-header-info">
                <div
                  className="mh-promo"
                  data-sal="slide-right"
                  data-sal-duration="0.8s"
                  data-sal-delay="100"
                >
                  <span>Hello I'm</span>
                </div>
                <h2
                  data-sal="slide-right"
                  data-sal-duration="0.8s"
                  data-sal-delay="200"
                >
                  Joachim Zeelmaekers
                </h2>
                <h3
                  data-sal="slide-right"
                  data-sal-duration="0.8s"
                  data-sal-delay="300"
                >
                  Full Stack Developer
                </h3>
                <ul>
                  <li
                    data-sal="slide-right"
                    data-sal-duration="0.8s"
                    data-sal-delay="400"
                  >
                    <i className="fa fa-envelope" />
                    <a href="mailto:zeelmaekersjoachim@gmail.com">
                      zeelmaekersjoachim@gmail.com
                    </a>
                  </li>
                </ul>
                <ul
                  className="social-icon fadeInUp"
                  data-sal-duration="0.8s"
                  data-sal-delay="600"
                >
                  {userLinks.map(link => {
                    if (link.label === "Email") {
                      return null;
                    }
                    return (
                      <li key={"social-key-" + link.label}>
                        <a
                          href={link.url}
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label={link.label}
                        >
                          <i className={link.iconClassName} />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="hero-img"
                data-sal="slide-left"
                data-sal-duration="0.8s"
                data-sal-delay="100"
              >
                <div className="img-border">
                  <img
                    src={
                      "https://res.cloudinary.com/webtric/image/upload/c_scale,q_80,w_500/v1607279884/webtric/joachim-profile-picture_zfm5yr.png"
                    }
                    alt="Joachim"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
