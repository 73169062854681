import React from "react";

const About = () => {
  return (
    <>
      <section className="mh-about" id="mh-about">
        <div className="container">
          <div className="row section-separator">
            <div className="col-sm-12 col-md-6">
              <div
                className="mh-about-img shadow-2"
                data-sal="fade"
                data-sal-duration="0.8s"
                data-sal-delay="200"
              >
                <img
                  src={"assets/web_developer.svg"}
                  alt="web developer"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="mh-about-inner">
                <h2
                  data-sal="fade"
                  data-sal-duration="0.8s"
                  data-sal-delay="100"
                >
                  About Me
                </h2>
                <p
                  data-sal="fade"
                  data-sal-duration="0.8s"
                  data-sal-delay="200"
                >
                  Hello, I’m Joachim, a full stack developer based in Belgium.
                  Since april 1st 2020, I started a small side business named
                  Webtric.
                </p>
                <p
                  data-sal="fade"
                  data-sal-duration="0.8s"
                  data-sal-delay="300"
                >
                  I focus on broadening my perspective on technology and try to
                  find the right tool for the job. My goal is to create
                  websites, web-applications or mobile applications for my
                  customers that actually solve their problem.
                </p>
                <div className="mh-about-tag">
                  <ul>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="400"
                    >
                      <span>Javascript/Typescript</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="450"
                    >
                      <span>React.js</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="500"
                    >
                      <span>Ionic</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="550"
                    >
                      <span>Node.js</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="600"
                    >
                      <span>Google Cloud Platform</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="650"
                    >
                      <span>Python</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="700"
                    >
                      <span>Java - Spring Boot</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="750"
                    >
                      <span>HTML5/CSS</span>
                    </li>
                    <li
                      data-sal="zoom-in"
                      data-sal-duration="0.8s"
                      data-sal-delay="800"
                    >
                      <span>Ember.js</span>
                    </li>
                  </ul>
                </div>
                {/* <a
                  href="/assets/resume.pdf"
                  className="btn btn-fill"
                  target="_blank"
                  alt="Resume | CV Joachim Zeelmaekers"
                  data-sal="zoom-in"
                  data-sal-duration="0.8s"
                  data-sal-delay="800"
                >
                  Download CV <i className="fa fa-download"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mh-service">
        <div className="container">
          <div className="row section-separator">
            <div
              className="col-sm-12 text-center section-title"
              data-sal-duration="0.8s"
              data-sal-delay="200"
            >
              <h2>What I do</h2>
            </div>
            <div className="col-sm-4">
              <div
                className="mh-service-item shadow-1 dark-bg"
                data-sal="flip-down"
                data-sal-duration="0.8s"
                data-sal-delay="200"
              >
                <i className="fa fa-bullseye purple-color" />
                <h3>Web development</h3>
                <p>
                  Web development for me is creating responsive websites or web
                  applications that are fast, work on all devices and are
                  accessible for users.
                </p>
                <p>
                  Did you know that a user is typically staying on a site longer
                  if the site speed is faster and they also convert better and
                  bounce less?
                  <br />{" "}
                  <strong>
                    For those reasons, Google has made it a ranking factor.{" "}
                  </strong>
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div
                className="mh-service-item shadow-1 dark-bg"
                data-sal="flip-down"
                data-sal-duration="0.8s"
                data-sal-delay="400"
              >
                <i className="fa fa-mobile-alt iron-color" />
                <h3>Mobile Development</h3>
                <p>
                  Creating a mobile application for Android and iOS doesn't have
                  to be difficult or expensive. Since I develop mobile apps for
                  both platforms at once, the costs are reduced and the app is
                  much more maintainable.
                </p>
                <p>
                  If you have any questions on this approach or do you want to
                  validate your app idea?
                  <br />
                  Give me a call{" "}
                  <a href={"callto:0032492600330"}>0492/60.03.30</a> !
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div
                className="mh-service-item shadow-1 dark-bg"
                data-sal="flip-down"
                data-sal-duration="0.8s"
                data-sal-delay="600"
              >
                <i className="fa fa-cloud sky-color" />
                <h3>Google cloud development</h3>
                <p>
                  Using Google Cloud Platform to host all the services makes
                  sure that all applications are running in a fully managed
                  secure environment.
                  <br />
                  <strong>
                    This helps me to focus on your idea or website.
                  </strong>
                </p>
                <p>
                  Google Cloud guarantees an uptime of 99.9. Still not
                  convinced? <br />
                  Checkout the{" "}
                  <a
                    href="https://cloud.google.com/customers"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Google website
                  </a>{" "}
                  to see their customer stories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
