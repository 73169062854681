const config = {
  siteTitle: "Webtric | web and mobile applications by Joachim Zeelmaekers", // Site title.
  siteTitleShort: "Webtric", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Joachim Zeelmaekers' developer portfolio", // Alternative site title for SEO.
  siteLogo: "/assets/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.webtric.be", // Domain of your website without pathPrefix.
  pathPrefix: "webtric", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "We focus on accessible websites and applications that are fast, beautifully designed and affordable.", // Website description used for RSS feeds/meta description tag.
  // Joachim Zeelmaekers is a young but experienced developer who creates websites, web applications and mobile apps. Next to this he also has a full time job as a Software developer.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Webtric's complete web applications by Joachim Zeelmaekers", // Title of the RSS feed
  siteFBAppID: "", // FB Application ID for using app insights
  googleAnalyticsID: "UA-163694767-3", // GA tracking ID.
  disqusShortname: "https-webtric-joachim-zeelmaekers-website", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 4, // Amount of posts displayed per listing page.
  userName: "Joachim Zeelmaekers", // Username to display in the author segment.
  userEmail: "zeelmaekersjoachim@gmail.com", // Email used for RSS feed's author segment
  userTwitter: "@joachimzee", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Belgium, Europe", // User location to display in the author segment.
  userAvatar:
    "https://media-exp1.licdn.com/dms/image/C5603AQG3c1rdrin1Ag/profile-displayphoto-shrink_200_200/0?e=1608163200&v=beta&t=4u8qmImmzvV6rceREoZEbYS_cyqasrk-Tx2UNWn1DDs", // User avatar to display in the author segment.
  userDescription:
    "Joachim Zeelmaekers is a young but experienced developer who creates websites, web applications and mobile apps. Next to this he also has a full time job as a Software developer.",
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/Joachimzeelmaekers",
      iconClassName: "fab fa-github",
    },
    {
      label: "Twitter",
      url: "https://twitter.com/joachimzee",
      iconClassName: "fab fa-twitter",
    },
    {
      label: "Email",
      url: "mailto:zeelmaekersjoachim@gmail.com",
      iconClassName: "fa fa-envelope",
    },
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/in/joachim-z/",
      iconClassName: "fab fa-linkedin",
    },
    {
      label: "Medium",
      url: "https://joachimzeelmaekers.medium.com",
      iconClassName: "fab fa-medium",
    },
    {
      label: "Dev",
      url: "https://dev.to/joachimzeelmaekers",
      iconClassName: "fab fa-dev",
    },
  ],
  copyright: "Copyright © 2020. Joachim Zeelmaekers | Webtric", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#0bceaf", // Used for setting manifest and progress theme colors.
  backgroundColor: "#FFFFFF", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
