import React, { useState } from "react";
import { useWindowScroll } from "react-use";

import "../styles/styles.scss";
import NavBar from "../components/nav/NavBar";
import Home from "../components/home/Home";
import About from "../components/about/About";
import Portfolio from "../components/portfolio/Portfolio";
import SEO from "../components/seo/SEO";
import Education from "../components/education/Education";
import MediumBlogs from "../components/mediumBlogs/MediumBlogs";
import Contact from "../components/contact/Contact";

export default function IndexPage() {
  const { y } = useWindowScroll();
  const [isChecked, setIsChecked] = useState(false);
  const [activeClass, setActiveClass] = useState("#mh-home");

  const baseClass =
    "black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav";

  return (
    <>
      <SEO isDark={isChecked} />
      <header
        className={`${baseClass} ${y >= 50 ? "nav-strict" : ""}`}
        id="mh-header"
      >
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <NavBar
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              activeClass={activeClass}
              setActiveClass={setActiveClass}
            />
          </div>
        </div>
      </header>

      <Home setActiveClass={setActiveClass} />
      <About setActiveClass={setActiveClass} />
      <Portfolio setActiveClass={setActiveClass} isChecked={isChecked} />
      <Education setActiveClass={setActiveClass} />
      {/* <MediumBlogs /> */}
      <footer className="mh-footer mh-footer-3" id="mh-contact">
        <Contact setActiveClass={setActiveClass} />
      </footer>
    </>
  );
}
