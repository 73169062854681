import React from "react";
import "./Switch.scss";

const Switch = ({ isChecked, setIsChecked, extraClass = "" }) => {
  // const baseMessage = "Toggle this switch to toggle dark mode."
  return (
    <div
      className={
        "switch-container " +
        (isChecked ? "bg-white-switch " : " ") +
        extraClass
      }
    >
      <label id="darkmode-toggle" className="switch">
        <input
          htmlFor="darkmode-toggle"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <span className="slider round">
          <i className="fa fa-moon " aria-hidden="true" />
        </span>
      </label>
    </div>
  );
};

export default Switch;
