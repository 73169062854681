import React, { useState } from "react";

import config from "../../../data/SiteConfig.js";
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
const { userLinks } = config || [];
const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.target;

    if (!firstName || !lastName || !message || !email) {
      setIsLoading(false);
      return;
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        message,
        email,
        name: firstName + " " + lastName,
      }),
    })
      .then(() => {
        setMessage("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleMessageInput = event => {
    setMessage(event.target.value);
  };
  return (
    <div className="container-fluid">
      <div className="row section-separator">
        <div
          className="col-sm-12 section-title wow fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
        >
          <h3>Hire me</h3>
        </div>
        <div className="map-image image-bg col-sm-12" id="contact-form">
          <div className="container mt-30">
            <div className="row">
              <div className="col-sm-12 col-md-6 mh-footer-address">
                <div className="col-sm-12 xs-no-padding">
                  <div
                    className="mh-address-footer-item dark-bg shadow-1 media wow fadeInUp"
                    data-sal="slide-right"
                    data-sal-duration="0.8s"
                    data-sal-delay="200"
                  >
                    <div className="each-icon">
                      <i className="fa fa-info" />
                    </div>
                    <div className="each-info media-body">
                      <h4>Contact information</h4>
                      <address>
                        Fill in the form or contact me using my contact details
                        below!
                      </address>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 xs-no-padding">
                  <div
                    className="mh-address-footer-item media dark-bg shadow-1"
                    data-sal="slide-right"
                    data-sal-duration="0.8s"
                    data-sal-delay="300"
                  >
                    <div className="each-icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="each-info media-body">
                      <h4>Email</h4>
                      <a href="mailto:zeelmaekersjoachim@gmail.com">
                        zeelmaekersjoachim@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6"
                data-toggle="validator"
                data-sal="slide-left"
                data-sal-duration="0.8s"
              >
                <form
                  id="contactForm"
                  name="contact"
                  className="single-form quate-form"
                  data-toggle="validator"
                  data-sal="slide-left"
                  data-sal-duration="0.2s"
                  data-sal-delay="200"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row">
                    <div className="col-sm-12">
                      <label id="firstname">First name</label>
                      <input
                        name="name"
                        className="contact-name form-control"
                        id="name"
                        type="text"
                        placeholder="First Name"
                        htmlFor="firstname"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-sm-12">
                      <label id="lastname">Last name</label>
                      <input
                        name="lastName"
                        className="contact-email form-control"
                        id="L_name"
                        type="text"
                        htmlFor="lastname"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-sm-12">
                      <label id="email">Email</label>
                      <input
                        name="email"
                        className="contact-subject form-control"
                        id="email"
                        type="email"
                        htmlFor="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-sm-12">
                      <label id="message">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        className="contact-message"
                        rows="6"
                        placeholder="Your idea or message"
                        htmlFor="message"
                        value={message}
                        onChange={handleMessageInput}
                        required
                      />
                    </div>
                    <div className="btn-form col-sm-12">
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-fill btn-block"
                        id="form-submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="col-sm-12 mh-copyright wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="text-left text-xs-center">
                      <p>
                        © All right reserved Webtric {new Date().getFullYear()}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <ul className="social-icon">
                      {userLinks.map(link => {
                        return (
                          <li key={"social-footer-key-" + link.label}>
                            <a
                              href={link.url}
                              target={"_blank"}
                              rel="noreferrer"
                              aria-label={link.label}
                            >
                              <i className={link.iconClassName} />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
