// Fetch new posts using:
// "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@joachimzeelmaekers";

const posts = [
  {
    title: "How to make the internet a safer place as a developer",
    pubDate: "2020-11-28 15:29:38",
    link:
      "https://uxdesign.cc/how-to-make-the-internet-a-safer-place-as-a-developer-cbae495ab55a?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/cbae495ab55a",
    author: "Joachim Zeelmaekers",
    thumbnail: "https://cdn-images-1.medium.com/max/2600/0*14TKEwSpaxAu9ch8",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://uxdesign.cc/how-to-make-the-internet-a-safer-place-as-a-developer-cbae495ab55a?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/2600/0*14TKEwSpaxAu9ch8" width="5184"></a></p>\n<p class="medium-feed-snippet">In combination with creating value for your users</p>\n<p class="medium-feed-link"><a href="https://uxdesign.cc/how-to-make-the-internet-a-safer-place-as-a-developer-cbae495ab55a?source=rss-8848b93b7f5------2">Continue reading on UX Collective »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://uxdesign.cc/how-to-make-the-internet-a-safer-place-as-a-developer-cbae495ab55a?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/2600/0*14TKEwSpaxAu9ch8" width="5184"></a></p>\n<p class="medium-feed-snippet">In combination with creating value for your users</p>\n<p class="medium-feed-link"><a href="https://uxdesign.cc/how-to-make-the-internet-a-safer-place-as-a-developer-cbae495ab55a?source=rss-8848b93b7f5------2">Continue reading on UX Collective »</a></p>\n</div>',
    enclosure: {},
    categories: [
      "programming",
      "product-design",
      "security",
      "user-experience",
      "ux",
    ],
  },
  {
    title: "Why You Should Create a Second Brain",
    pubDate: "2020-11-19 17:29:10",
    link:
      "https://medium.com/@joachimzeelmaekers/why-you-should-create-a-second-brain-ba5edae7eae2?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/ba5edae7eae2",
    author: "Joachim Zeelmaekers",
    thumbnail: "https://cdn-images-1.medium.com/max/2600/0*e9zCiPQIMFFHk8ld",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/why-you-should-create-a-second-brain-ba5edae7eae2?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/2600/0*e9zCiPQIMFFHk8ld" width="5760"></a></p>\n<p class="medium-feed-snippet">A brief introduction the concept of what a second brain is and how I benefit from it</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/why-you-should-create-a-second-brain-ba5edae7eae2?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/why-you-should-create-a-second-brain-ba5edae7eae2?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/2600/0*e9zCiPQIMFFHk8ld" width="5760"></a></p>\n<p class="medium-feed-snippet">A brief introduction the concept of what a second brain is and how I benefit from it</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/why-you-should-create-a-second-brain-ba5edae7eae2?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    enclosure: {},
    categories: ["brain", "writing", "notes", "productivity", "habit-building"],
  },
  {
    title: "Clean up your code by applying these 7 rules",
    pubDate: "2020-11-14 19:32:18",
    link:
      "https://medium.com/@joachimzeelmaekers/clean-up-your-code-by-applying-these-7-rules-2b13be6f0fa5?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/2b13be6f0fa5",
    author: "Joachim Zeelmaekers",
    thumbnail:
      "https://cdn-images-1.medium.com/max/1000/0*ZZpEwoObLQFE2c9U.jpg",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/clean-up-your-code-by-applying-these-7-rules-2b13be6f0fa5?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1000/0*ZZpEwoObLQFE2c9U.jpg" width="1000"></a></p>\n<p class="medium-feed-snippet">Readable code is maintainable code</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/clean-up-your-code-by-applying-these-7-rules-2b13be6f0fa5?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/clean-up-your-code-by-applying-these-7-rules-2b13be6f0fa5?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1000/0*ZZpEwoObLQFE2c9U.jpg" width="1000"></a></p>\n<p class="medium-feed-snippet">Readable code is maintainable code</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/clean-up-your-code-by-applying-these-7-rules-2b13be6f0fa5?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    enclosure: {},
    categories: [
      "code-style",
      "teamwork",
      "programming",
      "developer",
      "clean-code",
    ],
  },
  {
    title: "The 10 apps I use to boost my productivity",
    pubDate: "2020-11-08 20:54:05",
    link:
      "https://medium.com/the-innovation/the-10-apps-i-use-to-boost-my-productivity-934130d414b?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/934130d414b",
    author: "Joachim Zeelmaekers",
    thumbnail:
      "https://cdn-images-1.medium.com/max/1000/0*Tgyq9q8AIWTAHXrJ.jpg",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/the-innovation/the-10-apps-i-use-to-boost-my-productivity-934130d414b?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1000/0*Tgyq9q8AIWTAHXrJ.jpg" width="1000"></a></p>\n<p class="medium-feed-snippet">Learning how to use productivity tools, can improve your productivity. Checkout these 10 apps to help you level up your productivity.</p>\n<p class="medium-feed-link"><a href="https://medium.com/the-innovation/the-10-apps-i-use-to-boost-my-productivity-934130d414b?source=rss-8848b93b7f5------2">Continue reading on The Innovation »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/the-innovation/the-10-apps-i-use-to-boost-my-productivity-934130d414b?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1000/0*Tgyq9q8AIWTAHXrJ.jpg" width="1000"></a></p>\n<p class="medium-feed-snippet">Learning how to use productivity tools, can improve your productivity. Checkout these 10 apps to help you level up your productivity.</p>\n<p class="medium-feed-link"><a href="https://medium.com/the-innovation/the-10-apps-i-use-to-boost-my-productivity-934130d414b?source=rss-8848b93b7f5------2">Continue reading on The Innovation »</a></p>\n</div>',
    enclosure: {},
    categories: ["tips", "tools", "productivity", "apps", "product-management"],
  },
  {
    title: "What I did to survive my first couple of years as a developer",
    pubDate: "2020-11-01 16:31:49",
    link:
      "https://blog.usejournal.com/what-i-did-to-survive-my-first-couple-of-years-as-a-developer-98151f79b2d4?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/98151f79b2d4",
    author: "Joachim Zeelmaekers",
    thumbnail:
      "https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&height=300&w=600&q=100",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://blog.usejournal.com/what-i-did-to-survive-my-first-couple-of-years-as-a-developer-98151f79b2d4?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1128/0*BoQaoGQ3FgShMWdj.png" width="1128"></a></p>\n<p class="medium-feed-snippet">A short backstory</p>\n<p class="medium-feed-link"><a href="https://blog.usejournal.com/what-i-did-to-survive-my-first-couple-of-years-as-a-developer-98151f79b2d4?source=rss-8848b93b7f5------2">Continue reading on Noteworthy - The Journal Blog »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://blog.usejournal.com/what-i-did-to-survive-my-first-couple-of-years-as-a-developer-98151f79b2d4?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/1128/0*BoQaoGQ3FgShMWdj.png" width="1128"></a></p>\n<p class="medium-feed-snippet">A short backstory</p>\n<p class="medium-feed-link"><a href="https://blog.usejournal.com/what-i-did-to-survive-my-first-couple-of-years-as-a-developer-98151f79b2d4?source=rss-8848b93b7f5------2">Continue reading on Noteworthy - The Journal Blog »</a></p>\n</div>',
    enclosure: {},
    categories: [
      "life-lessons",
      "personal-development",
      "programming",
      "career-advice",
      "development",
    ],
  },
  {
    title:
      "Why Svelte and Tailwind is the perfect combination for a prototype.",
    pubDate: "2020-10-24 20:01:34",
    link:
      "https://medium.com/@joachimzeelmaekers/why-svelte-and-tailwind-is-the-perfect-combination-for-a-prototype-5fe74db416a9?source=rss-8848b93b7f5------2",
    guid: "https://medium.com/p/5fe74db416a9",
    author: "Joachim Zeelmaekers",
    thumbnail:
      "https://cdn-images-1.medium.com/max/600/1*HoyBco4EFoechMIicGlJGQ.png",
    description:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/why-svelte-and-tailwind-is-the-perfect-combination-for-a-prototype-5fe74db416a9?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/600/1*HoyBco4EFoechMIicGlJGQ.png" width="600"></a></p>\n<p class="medium-feed-snippet">I know what you think… Another frontend framework… Why another one, React is already amazing?!</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/why-svelte-and-tailwind-is-the-perfect-combination-for-a-prototype-5fe74db416a9?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    content:
      '<div class="medium-feed-item">\n<p class="medium-feed-image"><a href="https://medium.com/@joachimzeelmaekers/why-svelte-and-tailwind-is-the-perfect-combination-for-a-prototype-5fe74db416a9?source=rss-8848b93b7f5------2"><img src="https://cdn-images-1.medium.com/max/600/1*HoyBco4EFoechMIicGlJGQ.png" width="600"></a></p>\n<p class="medium-feed-snippet">I know what you think… Another frontend framework… Why another one, React is already amazing?!</p>\n<p class="medium-feed-link"><a href="https://medium.com/@joachimzeelmaekers/why-svelte-and-tailwind-is-the-perfect-combination-for-a-prototype-5fe74db416a9?source=rss-8848b93b7f5------2">Continue reading on Medium »</a></p>\n</div>',
    enclosure: {},
    categories: [
      "tailwind-css",
      "prototyping",
      "svelte",
      "react",
      "open-source",
    ],
  },
];

module.exports = posts;
