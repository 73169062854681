import React from "react";
import { useSwipeable } from "react-swipeable";

import featuredProjects from "../../../data/featuredProjects";
import "./Portfolio.scss";

const SLIDE_RIGHT = "slide-in-right";
const SLIDE_LEFT = "slide-in-left";

const Portfolio = ({ isChecked }) => {
  const totalSlides = featuredProjects.length;
  const baseColor = isChecked ? "white" : "black";
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [animationClass, setAnimationClass] = React.useState(SLIDE_RIGHT);
  const clickNextButton = () => {
    setAnimationClass(SLIDE_RIGHT);
    if (currentSlide === totalSlides - 1) {
      setCurrentSlide(0);
      return;
    }
    setCurrentSlide(currentSlide + 1);
  };

  const clickPreviousButton = () => {
    setAnimationClass(SLIDE_LEFT);

    if (currentSlide === 0) {
      setCurrentSlide(totalSlides - 1);
      return;
    }

    setCurrentSlide(currentSlide - 1);
  };

  const handlers = useSwipeable({
    onSwipedRight: eventData => clickPreviousButton(),
    onSwipedLeft: eventData => clickNextButton(),
    // ...config,
  });
  return (
    <section className="mh-featured-project image-bg featured-img-one">
      <div className="img-color-overlay">
        <div className="container" style={{ position: "relative" }}>
          <button
            className="port-button port-prev-button"
            onClick={clickPreviousButton}
            style={{ color: isChecked ? "white" : "black" }}
            aria-label="previous button"
          >
            <i className="fa fa-chevron-left" />
          </button>
          <button
            className="port-button port-next-button"
            onClick={clickNextButton}
            style={{ color: isChecked ? "white" : "black" }}
            aria-label="next button"
          >
            <i className="fa fa-chevron-right" />
          </button>
          <div className="row section-separator">
            <div className="section-title col-sm-12">
              <h3>Featured Projects</h3>
            </div>
            {featuredProjects.map((project, index) => {
              if (index !== currentSlide) {
                return null;
              }
              return (
                <div
                  className="col-sm-12 swipe-container"
                  key={project.title}
                  {...handlers}
                >
                  <div
                    className={
                      "mh-single-project-slide-by-side row " + animationClass
                    }
                  >
                    <div className="col-sm-12 mh-featured-item">
                      <div className="row">
                        <div
                          className="col-sm-7"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="mh-featured-project-img shadow-2">
                            <img
                              className="img-fluid"
                              src={project.image}
                              alt={project.title}
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="mh-featured-project-content">
                            <h4
                              className="project-category"
                              style={{ textTransform: "capitalize" }}
                            >
                              {project.projectCategory}
                            </h4>
                            <h2 style={{ textTransform: "capitalize" }}>
                              {project.title}
                            </h2>
                            <span style={{ textTransform: "capitalize" }}>
                              {project.category}
                            </span>
                            <p>{project.description}</p>
                            {project.url.includes("www.") ? (
                              <a
                                href={project.url}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-fill"
                              >
                                {project.buttonText}
                              </a>
                            ) : (
                              <a href={project.url} className="btn btn-fill">
                                {project.buttonText}
                              </a>
                            )}

                            <div className="mh-testimonial mh-project-testimonial">
                              {project.quote && project.quotePerson ? (
                                <blockquote>
                                  <q>{project.quote}</q>
                                  <cite>- {project.quotePerson}</cite>
                                </blockquote>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="bulbs">
          {new Array(totalSlides).fill(0).map((option, index) => {
            return (
              <button
                className={`bulb`}
                aria-label="bulb button"
                key={"bulb-key-option" + option + index}
                style={{
                  color: index !== currentSlide ? baseColor : "#0bceaf",
                }}
                onClick={() => setCurrentSlide(index)}
              >
                <i className="fa fa-circle"></i>
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
