const featuredProjects = [
  {
    projectCategory: "Web development",
    title: "Psylaris | Dashboard",
    category: "Design and platform development",
    description:
      "At Psylaris I developed and continue to maintain an application build for therapists that help people with posttraumatic stress disorder (PTSD). The dashboard also offers remote sessions with video call integration during COVID-19. I also provide hosting and CI/CD on Google Cloud Platform.",
    url: "https://www.psylaris.com",
    image:
      "https://res.cloudinary.com/webtric/image/upload/c_scale,w_629/v1607279928/webtric/Psylaris-dashboard-sample_c2t8dw.png",
    buttonText: "Learn more!",
    quote: "",
    quotePerson: "",
  },
  {
    projectCategory: "Web development",
    title: "De groene letters Website",
    category: "Design and web development",
    description:
      "This website is created for a local flower store. It is only available in Dutch. The idea behind the website is to give a clear idea of what differentiates this flower store from the others. This visually appealing website gives more credibility to the store, and helps customers find the information that they're looking for.",
    url: "https://www.degroeneletters.be",
    image:
      "https://res.cloudinary.com/webtric/image/upload/c_scale,q_100,w_617/v1607279907/webtric/degroenelettersvof_gtgl0e.png",
    id: "webtric/degroenelettersvof_gtgl0e.png",
    buttonText: "Checkout the website!",
    quote: "",
    quotePerson: "",
  },
  {
    projectCategory: "Your next website or application?",
    title: "Your website?",
    category: "Mobile or web development?",
    description:
      "Do you want help in visualizing your idea? Or do you want a fast, beautiful and responsive website for your business? ",
    url: "#contact-form",
    image:
      "https://res.cloudinary.com/webtric/image/upload/c_scale,q_100,w_722/v1607279927/webtric/your-next-website_ofjz3m.png",
    id: "webtric/your-next-website_ofjz3m.png",
    buttonText: "Hire me!",
    quote: "",
    quotePerson: "",
  },
];

export default featuredProjects;
